import './contact.scss';
import { useEffect } from 'react';

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section class="top-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="work">
                            <div class="col-md-8">
                                <h1>Get in Touch!</h1>
                                <a class="contact__link" href="mailto:marika.sadowska00@gmail.com">
                                    Reach out to me here
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact;