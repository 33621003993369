import './about.scss';
import image from '../../images/profile_image.png';
import { useEffect } from 'react';

function About() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div class="top-section">
            <div class="wrap">
                <div class="container-fluid">
                    <div class="row">
                        <div class="about col-lg-6 about__items about__text">
                            <h1>
                                Hey! My name is Marika
                            </h1>
                            <p>
                                I am passionate about developing and designing meaningful and user centric applications.
                            </p>
                            <p>
                                I received a Higher Diploma by completing a 2 year Software Development Apprenticeship programme by FIT Ireland.
                            </p>
                            <p>
                                During those 2 years I also got to work with Vaultree, an Irish cybersecurity startup. In Vaultree I was working as part of the Front-end team and occasionally helped out on the Design team. My main tasks revolved around the company website and brand designs.
                            </p>
                            <p>
                                In my spare time I enjoy drawing digitally, and playing video games. I am also passionate about cooking and baking, which I am constantly working on improving.
                            </p>
                        </div>
                        <div class="col-lg-6 about__items">
                            <img class="about__image" src={image} alt="Marika's Profile Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;