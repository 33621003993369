import Navbar from "./navBar"

function Header() {
    return (
        <>
            <header class="header">
                <Navbar />
            </header>
        </>
    )
};

export default Header;