import '../../styles/work.scss';
import image1 from '../../images/tingle-banner.png';
import image2 from '../../images/tingle-logo.png';
import image3 from '../../images/tingle-colors.png';
import image4 from '../../images/tingle-packaging.png';
import image5 from '../../images/tingle-site.png';
import { useEffect } from 'react';

function DesignTingle() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section class="top-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="work">
                            <div class="col-md-8">
                                <h1>Tingle Tea</h1>
                                <p class="work__text">
                                    Tingle Tea is an Irish loose leaf tea brand that prides themselves in premium quality tea, for an affordable price. For this project I have created a full brand design starting from the logo, to the website and packaging.
                                </p>
                                <p class="work__text">
                                    Tools used: Figma, Procreate.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="color-section color-section__green">
                <div class="work">
                    <div class="col-md-8">
                        <img src={image1} alt="Image of the front page of Tingle Tea design brand package" />
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h2>Overview</h2>
                            <p>
                                Tingle Tea is a loose leaf tea brand, whose main brand focus on the quality tea without a crazy price tag. The demographic that the brand wishes to reach are tea drinkers in their mid 20's to 60's who are interested in trying new teas. Tingle Tea's mission is to open the world of loose leaf tea to people who may not know about it.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 work__left">
                                <h3>Logo Design</h3>
                                <p class="work__text">
                                    From the intitial brainstorming, I knew I wanted to add a visual element to the logo, a feather, or a tea leaf which would instantly tell the customers what the brand is about. I created many sketches of both posibilities, and the final decision was made to go in the direction of the tea leaf.
                                </p>
                                <p>
                                    Once my sketches were reviewed, and after some discussion, it was decided that the brand wants to have a modern and simple feel. This is where the current logo lands. It is a modern logo with a simplified two leaves and a bud design, that perfectly fits a brand that sells loose leaf tea.
                                </p>
                            </div>
                            <div class="col-lg-6 work__right">
                                <img class="work__img" src={image2} alt="Logo sketches and final logo design." />
                            </div>
                        </div>
                        <div class="row work__row">
                            <div class="col-lg-6 work__left">
                                <img class="work__img" src={image3} alt="Brand colour palette and fonts." />
                            </div>
                            <div class="col-lg-6 work__right">
                                <h3>Colours and Fonts</h3>
                                <p class="work__text">
                                    To keep a modern and simple feel to the brand, it was decided to keep the colour palette minimalistic. 3 main colours - a black for copy and the logo, a white for backgrounds, and a primary brand colour a green. It was really important to choose the perfect shade of green that is versatile, and fits the modern aesthetic the brand wants to portray. Two secondary colours were selected: Oolong orange, and Red black. These colours would be used for packing and website for differentiating different tea types offered by the brand.
                                </p>
                                <p>
                                    As for typography, the ZCool font has been chosen at the logo design stage as part of the logo. This font would only be used for titles and headings, but not main copy. For the main copy and other text, the Mulish sans serif font was chosen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>Packaging Design</h3>
                            <p class="work__text">
                                While creating the packaging design it was important to keep the modern and simple feel the brand has requested, but without making it plain and boring. My goal was to create a design that would check those boxes, but also stand out amongst other tea brands when displayed in stores. The packaging decided upon were doypacks with a matte finish.
                            </p>
                            <p class="work__text">
                                The front of the package is the first thing all customer would see, so it was extremely important to include all the relevant information that a customer expects, with a design that is simple yet eye catching. Most tea brands use very intricate patterns and use very deep, darks colours. So we decided to try going into the opposite direction of using bright colours and simplified patterns. Using the logo's leaf symbol, I created the pattern for the packaging.
                            </p>
                            <div>
                                <img class="work__img" src={image4} alt="Packaging design on a doypack." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>Website Design</h3>
                            <p class="work__text">
                                The final step was the create the UI for the Tingl Tea website. My first step was to research other tea brand's websites. I knew it was important to design a website that feels familiar, but keep it on brand. I complied a list of sections and elements other websites had so that I could create a design that fits the industry standards.
                            </p>
                            <p class="work__text">
                                As this is an e-commerce website so I decided to design using the mobile first approach, by starting from the mobile web design and then wider screen size design.
                            </p>
                            <div>
                                <img class="work__img" src={image5} alt="Desktop and mobile designs of the website UI." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DesignTingle;