import '../../styles/work.scss';
import image1 from '../../images/portfolio-banner.png';
import image2 from '../../images/portfolio-plans.png';
import image3 from '../../images/portfolio-design.png';
import image4 from '../../images/portfolio-code.png';
import image5 from '../../images/portfolio-conclusion.png';
import { useEffect } from 'react';

function Portfolio() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section class="top-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="work">
                            <div class="col-md-8">
                                <h1>Portfolio Website</h1>
                                <p class="work__text">
                                    The biggest personal project I have worked on thus far is my portfolio website (which you are currently on). From the very initial concept, design and then to code, I have created this portfolio myself with the knowledge and experience I have gathered.
                                </p>
                                <p class="work__text">
                                    Technologies used throughout: ReactJS, HTML5, CSS, SASS, and JavaScript.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="color-section color-section__purple">
                <div class="work">
                    <div class="col-md-8">
                        <img src={image1} alt="Portfolio page banner." />
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h2>Overview</h2>
                            <p>
                                I have started this project, my personal portfolio, as part of my learning React journey. It was my first ever project written using React, and first ever website project I had to setup and host myself. It was a fun project to start my React journey with!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>Ideas and Wireframing</h3>
                            <p class="work__text">
                                Just like with any other applicaiton or website, I started with coming up with ideas for my portfolio. This step sets the foundation for the rest of the project so I made sure to not skip out on it.
                            </p>
                            <p class="work__text">
                                Firstly, I went to the internet to look at what other people have done, and compiled a list of websites or just images, that I found to be intersting and appealling to me. I used these as inspiration and to create a mood board, so I can stay on track for my next steps.
                            </p>
                            <p class="work__text">
                                I then began to create very simple wireframe sketches that will help and guide me through the design stage. It was important that before I started wireframing, I had a list of pages I wanted to have on the websites. Initially I had a different structure in mind, but I later on realised that it would make the user experience better if I split my work into design and development work into seperate dropdowns.
                            </p>
                            <div>
                                <img class="work__img" src={image2} alt="Wireframes and sketches." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 work__left">
                                <h3>UI Design Process</h3>
                                <p class="work__text">
                                    As I had a solid idea of my desired layout from the previous wireframing stage, I had a much easier time with creating higher level designs and concepts for the website.
                                </p>
                                <p>
                                    I firstly came up with a very simple colour palette for the project, and found my desired fonts. I made sure to set some rules regarding where and when certaisn colours and fonts can be used, to keep consisntacy throughout the design.
                                </p>
                            </div>
                            <div class="col-lg-6 work__right">
                                <img class="work__img" src={image3} alt="Image of demo page from vaultee.com" />
                            </div>
                        </div>
                        <div class="row work__row">
                            <div class="col-lg-6 work__left">
                                <img class="work__img" src={image4} alt="Image of demo page from vaultee.com" />
                            </div>
                            <div class="col-lg-6 work__right">
                                <h3>Turning Design into Code</h3>
                                <p class="work__text">
                                    Once I was happy with my designs, it was time to move onto one of the final stages: coding. I was relucatant about using React at first, as I have never used it before, but I knew this would be the best opportunity for me to learn.
                                </p>
                                <p>
                                    Since the design is very simple, and there aren't any fancy animations, it was a pretty straightforward process. I learned a lot about how React works, but there is always more left to learn.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bot-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>The Conclusion</h3>
                            <p class="work__text">
                                To conclude my work on this Portfolio project, I was very happy with the outcome, especially since it was my first time using React, so there were a lot of new things I had to research and learn.
                            </p>
                            <p class="work__text">
                                As for the final outcome compared to the intial design, I am really happy with how close I was able to get to my inital designs. I am happy with how responsive the website is, and thus far is has worked great on mobile, laptop, iPad and desktop.
                            </p>
                            <p class="work__text">
                                Using React made me realise how powerful it is, and made me excited about learning and creating more projects usign it. I definatly want to work on new projects with React, and in the future work on improving this website by making it more fun.
                            </p>
                            <div>
                                <img class="work__img" src={image5} alt="Image of UI design for mobile app called CamoCab." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Portfolio;