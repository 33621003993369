import { menuItemsData } from "./navData";
import NavItems from "./navItems";
import './nav.scss';
import { useState, useEffect } from "react";

const Navbar = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
    }, []);
    return (
        <nav className="nav">
            <ul className={scroll ? "nav__list on-scroll" : "nav__list"}>
                {menuItemsData.map((menu, index) => {
                    return <NavItems items={menu} key={index} />;
                })}
            </ul>
        </nav>
    );
};

export default Navbar;