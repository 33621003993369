import './home.scss';

function Home() {
    return (
        <div class="top-section">
            <div class="wrap">
                <div class="container-fluid">
                    <div class="home-title">
                        <h1>Hey I'm Marika<br></br>A Developer & Designer</h1>
                    </div>
                    <div class="home-text">
                        <p>
                            I love coming up with new ideas and turning them<br></br>into reality with code.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home