import { NavLink } from "react-router-dom";

const Dropdown = ({ submenus, dropdown }) => {
    return (
        <ul className={`dropdown ${dropdown ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <li key={index} className="nav__items">
                    <NavLink to={submenu.url} className={({ isActive }) =>
                        isActive ? "active" : ""
                    }>
                        {submenu.title}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;