import '../../styles/work.scss';
import image1 from '../../images/vaultree-design.png';
import image2 from '../../images/demo-page.png';
import image3 from '../../images/encrypted-taxi.png';
import image4 from '../../images/sketches-swag.png';
import image5 from '../../images/illustrations-2-swag.png';
import { useEffect } from 'react';

function DesignVaultree() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section class="top-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="work">
                            <div class="col-md-8">
                                <h1>Vaultree</h1>
                                <p class="work__text">
                                    A collection of work I have completed for Vaultree, an Irish cybersecurity startup that has developed a data-in-use solution. At Vaultree I worked on the front-end team and the design team. My main tasks were the website and graphic & UI work.
                                </p>
                                <p class="work__text">
                                    Tools used: Figma, Illustrator, Photoshop, HTML5, CSS, and SASS.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="color-section color-section__blue">
                <div class="work">
                    <div class="col-md-8">
                        <img src={image1} alt="Image of collection of design work, some sketches and finished illustartion" />
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h2>Overview</h2>
                            <p>
                                My main role at Vaultree was on the Front-end team as a developer, however I soon began to gain more interest in the design process and creating designs myself. Afetr a while I have been asked to occassionally help out and be a part of the design team too. I started off mainly doing graphic designs and illustartions, but as time went by, I also got the opportunity to work on bigger projects like new page UI's and even application UI designs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>Encrypted Taxi App UI Design</h3>
                            <p class="work__text">
                                This is a UI design I created as part of a Hackathon at Vaultree. The leader of the team came up with the idea of an encrypted taxi app. This app would encrypt users location, both the drivers and the one booking the service, before the booking is completed. Once the driver accepts the request, the encrypted address would be decrypted with a private key, to allow the driver know the location of the person who they are meant to pick up.
                            </p>
                            <p class="work__text">
                                As the only designer on the team, it was my resposibility to take the idea of an encrypted taxi service and turn it into a mockup UI design. It's a pretty simple design, but it was enough to have a visual representation of our idea. For this design, I used procreate for initial sketches and moved straight into high fidelity designs in Figma due to the time constraint.
                            </p>
                            <div>
                                <img class="work__img" src={image3} alt="Image of UI design for mobile app called CamoCab." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 work__left">
                                <h3>Illustrations for Swag Store items</h3>
                                <p class="work__text">
                                    This project was heavily focussed on creating illustrations that match the brand of the company (Vaultree), but also would look good on swag items, like notebooks, totes etc. I started this project off by creating a mood board and from that I started sketching.
                                </p>
                                <p class="work__text">
                                    After having my sketches reviewed, I started working on turning the selected ones into SVG illustartions. I mostly used Figma for this process.
                                </p>
                            </div>
                            <div class="col-lg-6 work__right">
                                <img class="work__img" src={image4} alt="Image of demo page from vaultee.com" />
                            </div>
                        </div>
                        <div>
                            <p class="work__text">
                                One of the website page UI's that I have designed was the /demo page on the Vaultree official website. This page has recently went through a design update as we completely re-designed the Vaultree website. This was a fun project to work on solo, with some reviews and guidance from a fellow designer from the team.
                            </p>
                            <p class="work__text">
                                I created a new illustartion for this page to convey the meaning on the copy on the page, into a more visual representation for the users.
                            </p>
                            <div>
                                <img class="work__img" src={image5} alt="Image of demo page from vaultee.com" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bot-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>Demo Page UI Design</h3>
                            <p class="work__text">
                                One of the website page UI's that I have designed was the /demo page on the Vaultree official website. This page has recently went through a design update as we completely re-designed the Vaultree website. This was a fun project to work on solo, with some reviews and guidance from a fellow designer from the team.
                            </p>
                            <p class="work__text">
                                I created a new illustartion for this page to convey the meaning on the copy on the page, into a more visual representation for the users.
                            </p>
                            <div>
                                <img class="work__img" src={image2} alt="Image of demo page from vaultee.com" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default DesignVaultree;