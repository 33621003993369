import './styles/index.scss';

// Components
import Header from './components/nav/header';
import Footer from './components/footer/footer';

// Single layer pages
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import About from './pages/about/about';

// Multi layer DESIGN pages
import DesignVaultree from './pages/design/designVaultree';
import DesignTingle from './pages/design/designTingle';

// Multi layer DEV pages
import DevVaultree from './pages/dev/devVaultree';
import Portfolio from './pages/dev/portfolio';
import DevApprenticeship from './pages/dev/apprenticeship';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="design-vaultree" element={<DesignVaultree />} />
          <Route path="design-tingletea" element={<DesignTingle />} />
          <Route path="dev-vaultree" element={<DevVaultree />} />
          <Route path="dev-apprenticeship" element={<DevApprenticeship />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  )
}

export default App;
