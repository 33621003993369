export const menuItemsData = [
    {
        title: 'Hey',
        url: '/',
    },
    {
        title: 'Dev',
        url: '/dev',
        submenu: [
            {
                title: 'Vaultree',
                url: 'dev-vaultree',
            },
            {
                title: 'Apprenticeship',
                url: 'dev-apprenticeship',
            },
            {
                title: 'Portfolio',
                url: '/portfolio',
            },
        ]
    },
    {
        title: 'Design',
        url: '/design',
        submenu: [
            {
                title: 'Vaultree',
                url: 'design-vaultree',
            },
            {
                title: 'Tingle Tea',
                url: 'design-tingletea',
            },
            {
                title: 'Portfolio',
                url: '/portfolio',
            },
        ]
    },
    {
        title: 'About',
        url: '/about',
    },
    {
        title: 'Contact',
        url: '/contact',
    },
];