import Dropdown from "./dropdown";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

const NavItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    let ref = useRef();

    const [isActive, setIsActive] = useState(false);

    const closeActive = () => {
        isActive && setIsActive(false);
    };

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
            if (isActive && ref.current && !ref.current.contains(event.target)) {
                setIsActive(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown], [isActive]);

    // useClass(() => {
    //     const handler = (event) => {
    //         if (isActive && ref.current && !ref.current.contains(event.target)) {
    //             setDropdown(false);
    //         }
    //     };
    //     document.addEventListener("mousedown", handler);
    //     document.addEventListener("touchstart", handler);
    //     return () => {
    //         document.removeEventListener("mousedown", handler);
    //         document.removeEventListener("touchstart", handler);
    //     };
    // }, [isActive]);

    return (
        <li className="nav__items" ref={ref} onClick={() => { closeDropdown(); closeActive(); }}>
            {items.submenu ? (
                <>
                    <button className={isActive ? "active" : ""} type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"}
                        onClick={(event) => { setDropdown((prev) => !prev); setIsActive((prev) => !prev); }}>
                        {items.title}{' '}
                    </button>
                    <Dropdown submenus={items.submenu} dropdown={dropdown} />
                </>
            ) : (
                <NavLink to={items.url} className={({ isActive }) =>
                    isActive ? "active" : ""
                }>
                    {items.title}
                </NavLink>
            )
            }
        </li >
    );
};

export default NavItems;