import { NavLink } from "react-router-dom";
import './footer.scss'
import githubImage from '../../images/github-png.png';
import dribbleImage from '../../images/dribble-png.png';
import linkedinImage from '../../images/linkedin-png.png';

function Footer() {
    return (
        <>
            <footer class="footer">
                <div class="row footer__row">
                    <div class="col-lg-4 footer__groups">
                        <div class="footer__items logos">
                            <NavLink to="https://github.com/marika-sa" activeStyle>
                                <img src={githubImage} alt="Github logo png anchor tag" />
                            </NavLink>
                        </div>
                        <div class="footer__items logos">
                            <NavLink to="https://dribbble.com/marika-sa" activeStyle>
                                <img src={dribbleImage} alt="Dribble logo png anchor tag" />
                            </NavLink>
                        </div>
                        <div class="footer__items logos">
                            <NavLink to="https://www.linkedin.com/in/marika-sadowska/" activeStyle>
                                <img src={linkedinImage} alt="LinkedIn logo png anchor tag" />
                            </NavLink>
                        </div>
                    </div>
                    <div class="col-lg-4 footer__groups">
                        <div class="footer__name">
                            <p>
                                Marika Sadowska {new Date().getFullYear()}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 footer__groups">
                        <div class="footer__items">
                            <NavLink to="/contact" activeStyle>
                                Contact
                            </NavLink>
                        </div>
                        <div class="footer__items">
                            <NavLink to="/about" activeStyle>
                                About
                            </NavLink>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;