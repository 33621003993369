import { useEffect } from 'react';
import '../../styles/work.scss';
import image1 from '../../images/apprenticeship-banner.png';
import image2 from '../../images/app-bank.png';
import image3 from '../../images/app-paycheck.png';
import image4 from '../../images/app-paycheck.png';
import image5 from '../../images/apprenticeship-carapp.png';
import image6 from '../../images/app-message.png';

function DevApprenticeship() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section class="top-section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="work">
                            <div class="col-md-8">
                                <h1>Apprenticehsip</h1>
                                <p class="work__text">
                                    As part of my Software Development Apprenticeship, I attended college and completed 10+ coding modules, and continuous assessments ranging from database creation with MySQL, a banking app created in C#, and a game of Black Jack written in Java.
                                </p>
                                <p class="work__text">
                                    Technologies used throughout: C#.Net, Java, Python, MySQL.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="color-section color-section__pink">
                <div class="work">
                    <div class="col-md-8">
                        <img src={image1} alt="Apprenticeship banner of GUI apps." />
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h2>Overview</h2>
                            <p>
                                The first six months of my Apprenticehsip, I attened college classes full-time, and the remainder 1.5 years were part-time 2 days college and 3 days work. These were level 6 NFQ standard classes, with software development modules such as object oriented programming, software development cycle, event driven programming, software testing etc.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>Banking App: C# .NET & MySQL</h3>
                            <p class="work__text">
                                This application was created as part of my event driven programming module and assigment. It is a prtty simple banking applicaiton created with C# using the .NET framework, and an external MySQL database to store users and their data.
                            </p>
                            <p class="work__text">
                                As the banking app is heavily event full applicaiton, it only made sense to use the event driven programming paradigm. The events that happen in the application iswhat determines the flow of the application.
                            </p>
                            <p class="work__text">
                                When a user clicks the login button, they are either logged into their account if their information matches an existing account or get an error login message. Once a user is logged into their account, they can view their details: name, balance and account number. They also get a few options they can perform like withdraw or deposit money into their accounts and logout of their account.
                            </p>
                            <div>
                                <img class="work__img" src={image2} alt="Login screen UI, account screen UI with success message pop-up, and withdraw failure message pop-up all in the banking application mentioned above." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6 work__left">
                                <h3>Black Jack Game: Java</h3>
                                <p class="work__text">
                                    This was a personal project I created as part of my independant learning. As I already had experience with coding in C#, learning Java was a more simple process. As part of my learning journey, I decided to take on a challenege of creating this small project to take what I have learned and apply it. This game was created using OOP principals using classes and objects.
                                </p>
                                <p>
                                    In this game, a user is put against the computer. How the game works is a user is prompted to draw cards. They are then shown their hand and can decide whether they want to draw again or not. The computer gets their own hand and has the same option to draw or not. Once both parties have drawn all the cards they desire, both hands are revealed and the outcome is displayed: X is the winner!
                                </p>
                            </div>
                            <div class="col-lg-6 work__right">
                                <img class="work__img" src={image3} alt="Console game of Black Jack written in Java" />
                            </div>
                        </div>
                        <div class="row work__row">
                            <div class="col-lg-6 work__left">
                                <img class="work__img" src={image4} alt="Employee payroll displayed in the console." />
                            </div>
                            <div class="col-lg-6 work__right">
                                <h3>Employee Paycheck App: Python</h3>
                                <p class="work__text">
                                    An app I created as part of a programming assigment for my apprenticeship. It is a very simple terminal applicaiton, that allows users to look throuh employee details, such as their full name, PPSN, and salary. The user also has the option to print out employee payslips, view payrolls, and add new employees.
                                </p>
                                <p>
                                    This was my first applicaiton written in Python, using the procedural programming model. Everything that a user is able to do within the app is done and handled by functions specifically created for those tasks.
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 work__left">
                                <h3>Car Rental Database App: C#.Net & MySQL</h3>
                                <p class="work__text">
                                    Another application created with C# and .NET, MySQL for the database and Visual Studio GUI builder for the GUI.
                                </p>
                                <p>
                                    It is an application that is meant to be used for finding car rentals, with also an option of uploading new cars to the database for car rental. The main screen of the application allows the user to look through available cars and add or delete cars. The second screen is used for filtering through the cars stored in the database.
                                </p>
                            </div>
                            <div class="col-lg-6 work__right">
                                <img class="work__img" src={image5} alt="Image of demo page from vaultee.com" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="wrap">
                    <div class="container-fluid">
                        <div>
                            <h3>SMS and MMS Messaging App: C#.Net & MySQL</h3>
                            <p class="work__text">
                                This app was created using object oriented programming principals. It is the most complex applicaiton I have created, that has many diffrent functionalities within the applicaiton, and has an external database, that I created with MySQL.
                            </p>
                            <p class="work__text">
                                It has a very basic design of a messaging applicaiton, that allows users to read old messages, send new ones, but also send diffrent format messages such as videos, music or images as MMS. Users need to input the recipiants phone number, and create a message before they can send it. They can also go through old messages and delete them.
                            </p>
                            <div>
                                <img class="work__img" src={image6} alt="Image of UI design for mobile app called CamoCab." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DevApprenticeship;